define("discourse/plugins/discourse-ai/discourse/admin/models/ai-persona", ["exports", "@glimmer/tracking", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _tracking, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CREATE_ATTRIBUTES = ["id", "name", "description", "commands", "system_prompt", "allowed_group_ids", "enabled", "system", "priority", "top_p", "temperature", "user_id", "mentionable", "default_llm", "user", "max_context_posts", "vision_enabled", "vision_max_pixels", "rag_uploads", "rag_chunk_tokens", "rag_chunk_overlap_tokens", "rag_conversation_chunks", "question_consolidator_llm", "allow_chat"];
  const SYSTEM_ATTRIBUTES = ["id", "allowed_group_ids", "enabled", "system", "priority", "user_id", "mentionable", "default_llm", "user", "max_context_posts", "vision_enabled", "vision_max_pixels", "rag_uploads", "rag_chunk_tokens", "rag_chunk_overlap_tokens", "rag_conversation_chunks", "question_consolidator_llm", "allow_chat"];
  let CommandOption = (_class = class CommandOption {
    constructor() {
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  class AiPersona extends _rest.default {
    // this code is here to convert the wire schema to easier to work with object
    // on the wire we pass in/out commands as an Array.
    // [[CommandName, {option1: value, option2: value}], CommandName2, CommandName3]
    // So we rework this into a "commands" property and nested commandOptions
    init(properties) {
      if (properties.commands) {
        properties.commands = properties.commands.map(command => {
          if (typeof command === "string") {
            return command;
          } else {
            let [commandId, options] = command;
            for (let optionId in options) {
              if (!options.hasOwnProperty(optionId)) {
                continue;
              }
              this.getCommandOption(commandId, optionId).value = options[optionId];
            }
            return commandId;
          }
        });
      }
      super.init(properties);
      this.commands = properties.commands;
    }
    async createUser() {
      const result = await (0, _ajax.ajax)(`/admin/plugins/discourse-ai/ai-personas/${this.id}/create-user.json`, {
        type: "POST"
      });
      this.user = result.user;
      this.user_id = this.user.id;
      return this.user;
    }
    getCommandOption(commandId, optionId) {
      this.commandOptions ||= {};
      this.commandOptions[commandId] ||= {};
      return this.commandOptions[commandId][optionId] ||= new CommandOption();
    }
    populateCommandOptions(attrs) {
      if (!attrs.commands) {
        return;
      }
      let commandsWithOptions = [];
      attrs.commands.forEach(commandId => {
        if (typeof commandId !== "string") {
          commandId = commandId[0];
        }
        if (this.commandOptions && this.commandOptions[commandId]) {
          let options = this.commandOptions[commandId];
          let optionsWithValues = {};
          for (let optionId in options) {
            if (!options.hasOwnProperty(optionId)) {
              continue;
            }
            let option = options[optionId];
            optionsWithValues[optionId] = option.value;
          }
          commandsWithOptions.push([commandId, optionsWithValues]);
        } else {
          commandsWithOptions.push(commandId);
        }
      });
      attrs.commands = commandsWithOptions;
    }
    updateProperties() {
      let attrs = this.system ? this.getProperties(SYSTEM_ATTRIBUTES) : this.getProperties(CREATE_ATTRIBUTES);
      attrs.id = this.id;
      this.populateCommandOptions(attrs);
      return attrs;
    }
    createProperties() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      this.populateCommandOptions(attrs);
      return attrs;
    }
    workingCopy() {
      let attrs = this.getProperties(CREATE_ATTRIBUTES);
      this.populateCommandOptions(attrs);
      return AiPersona.create(attrs);
    }
  }
  _exports.default = AiPersona;
});