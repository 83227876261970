define("discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse/components/d-button", "discourse-common/helpers/i18n", "truth-helpers/helpers/not", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _helper, _object, _didInsert, _dButton, _i18n, _not, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AiHelperCustomPrompt;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AiHelperCustomPrompt = _exports.default = (_class = (_AiHelperCustomPrompt = class AiHelperCustomPrompt extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_customPromptInput", _descriptor, this);
    }
    setupCustomPrompt() {
      this._customPromptInput = document.querySelector(".ai-custom-prompt__input");
      this._customPromptInput.focus();
    }
    sendInput() {
      return this.args.submit(this.args.promptArgs);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="ai-custom-prompt" {{didInsert this.setupCustomPrompt}}>
        <Input
          @value={{@value}}
          placeholder={{i18n
            "discourse_ai.ai_helper.context_menu.custom_prompt.placeholder"
          }}
          class="ai-custom-prompt__input"
          @enter={{this.sendInput}}
        />
  
        <DButton
          @icon="discourse-sparkles"
          @action={{fn @submit @promptArgs}}
          @disabled={{not @value.length}}
          class="ai-custom-prompt__submit btn-primary"
        />
      </div>
    
  */
  {
    "id": "R9H8RJGc",
    "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"ai-custom-prompt\"],[4,[32,0],[[30,0,[\"setupCustomPrompt\"]]],null],[12],[1,\"\\n      \"],[8,[32,1],[[16,\"placeholder\",[28,[32,2],[\"discourse_ai.ai_helper.context_menu.custom_prompt.placeholder\"],null]],[24,0,\"ai-custom-prompt__input\"]],[[\"@value\",\"@enter\"],[[30,1],[30,0,[\"sendInput\"]]]],null],[1,\"\\n\\n      \"],[8,[32,3],[[24,0,\"ai-custom-prompt__submit btn-primary\"]],[[\"@icon\",\"@action\",\"@disabled\"],[\"discourse-sparkles\",[28,[32,4],[[30,2],[30,3]],null],[28,[32,5],[[30,1,[\"length\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@value\",\"@submit\",\"@promptArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt.js",
    "scope": () => [_didInsert.default, _component2.Input, _i18n.default, _dButton.default, _helper.fn, _not.default],
    "isStrictMode": true
  }), _AiHelperCustomPrompt), _AiHelperCustomPrompt), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_customPromptInput", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupCustomPrompt", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupCustomPrompt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendInput"), _class.prototype)), _class);
});