define("discourse/plugins/discourse-ai/discourse/components/ai-persona-command-option-editor", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _AiPersonaCommandOptionEditor;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let AiPersonaCommandOptionEditor = _exports.default = (_class = (_AiPersonaCommandOptionEditor = class AiPersonaCommandOptionEditor extends _component.default {
    get isBoolean() {
      return this.args.option.type === "boolean";
    }
    get selectedValue() {
      return this.args.option.value.value === "true";
    }
    onCheckboxChange(event1) {
      this.args.option.value.value = event1.target.checked ? "true" : "false";
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="control-group ai-persona-command-option-editor">
        <label>
          {{@option.name}}
        </label>
        <div class="">
          {{#if this.isBoolean}}
            <input
              type="checkbox"
              checked={{this.selectedValue}}
              {{on "click" this.onCheckboxChange}}
            />
            {{@option.description}}
          {{else}}
            <Input @value={{@option.value.value}} />
          {{/if}}
        </div>
        {{#unless this.isBoolean}}
          <div class="ai-persona-command-option-editor__instructions">
            {{@option.description}}
          </div>
        {{/unless}}
      </div>
    
  */
  {
    "id": "TVmYtqUc",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group ai-persona-command-option-editor\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"isBoolean\"]],[[[1,\"          \"],[11,\"input\"],[16,\"checked\",[30,0,[\"selectedValue\"]]],[24,4,\"checkbox\"],[4,[32,0],[\"click\",[30,0,[\"onCheckboxChange\"]]],null],[12],[13],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],null,[[\"@value\"],[[30,1,[\"value\",\"value\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"isBoolean\"]]],[[[1,\"        \"],[10,0],[14,0,\"ai-persona-command-option-editor__instructions\"],[12],[1,\"\\n          \"],[1,[30,1,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@option\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-persona-command-option-editor.js",
    "scope": () => [_modifier.on, _component2.Input],
    "isStrictMode": true
  }), _AiPersonaCommandOptionEditor), _AiPersonaCommandOptionEditor), (_applyDecoratedDescriptor(_class.prototype, "onCheckboxChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCheckboxChange"), _class.prototype)), _class);
});