define("discourse/plugins/discourse-ai/discourse/admin/adapters/ai-llm", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Adapter extends _rest.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "jsonMode", true);
    }
    basePath() {
      return "/admin/plugins/discourse-ai/";
    }
    pathFor(store, type, findArgs) {
      // removes underscores which are implemented in base
      let path = this.basePath(store, type, findArgs) + store.pluralize(this.apiNameFor(type));
      return this.appendQueryParams(path, findArgs);
    }
    apiNameFor() {
      return "ai-llm";
    }
  }
  _exports.default = Adapter;
});