define("discourse/plugins/discourse-ai/discourse/connectors/split-new-topic-tag-after/ai-tag-suggestion", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _aiSplitTopicSuggester, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AiTagSuggestion;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AiTagSuggestion = _exports.default = (_class = (_AiTagSuggestion = class AiTagSuggestion extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showPostAIHelper)(outletArgs1, helper1);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.siteSettings.ai_embeddings_enabled}}
        <AiSplitTopicSuggester
          @selectedPosts={{@outletArgs.selectedPosts}}
          @mode="suggest_tags"
          @updateAction={{@outletArgs.updateTags}}
          @currentValue={{@outletArgs.tags}}
        />
      {{/if}}
    
  */
  {
    "id": "jo6+zYZR",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"ai_embeddings_enabled\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@selectedPosts\",\"@mode\",\"@updateAction\",\"@currentValue\"],[[30,1,[\"selectedPosts\"]],\"suggest_tags\",[30,1,[\"updateTags\"]],[30,1,[\"tags\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/split-new-topic-tag-after/ai-tag-suggestion.js",
    "scope": () => [_aiSplitTopicSuggester.default],
    "isStrictMode": true
  }), _AiTagSuggestion), _AiTagSuggestion), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});