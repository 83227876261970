define("discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-llm-selector", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/runloop", "@ember/service", "discourse/lib/key-value-store", "I18n", "select-kit/components/dropdown-select-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _runloop, _service, _keyValueStore, _I18n, _dropdownSelectBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _BotSelector;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function isBotMessage(composer1, currentUser1) {
    if (composer1 && composer1.targetRecipients && currentUser1.ai_enabled_chat_bots) {
      const reciepients1 = composer1.targetRecipients.split(",");
      return currentUser1.ai_enabled_chat_bots.any(bot1 => reciepients1.any(username1 => username1 === bot1.username));
    }
    return false;
  }
  let BotSelector = _exports.default = (_class = (_BotSelector = class BotSelector extends _component.default {
    static shouldRender(args1, container1) {
      return container1?.currentUser?.ai_enabled_personas && isBotMessage(args1.model, container1.currentUser);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "llm", _descriptor3, this);
      _defineProperty(this, "STORE_NAMESPACE", "discourse_ai_persona_selector_");
      _defineProperty(this, "LLM_STORE_NAMESPACE", "discourse_ai_llm_selector_");
      _defineProperty(this, "preferredPersonaStore", new _keyValueStore.default(this.STORE_NAMESPACE));
      _defineProperty(this, "preferredLlmStore", new _keyValueStore.default(this.LLM_STORE_NAMESPACE));
      if (this.botOptions && this.composer) {
        let personaId1 = this.preferredPersonaStore.getObject("id");
        this._value = this.botOptions[0].id;
        if (personaId1) {
          personaId1 = parseInt(personaId1, 10);
          if (this.botOptions.any(bot1 => bot1.id === personaId1)) {
            this._value = personaId1;
          }
        }
        this.composer.metaData = {
          ai_persona_id: this._value
        };
        let llm1 = this.preferredLlmStore.getObject("id");
        llm1 = llm1 || this.llmOptions[0].id;
        if (llm1) {
          (0, _runloop.next)(() => {
            this.currentLlm = llm1;
          });
        }
      }
    }
    get composer() {
      return this.args?.outletArgs?.model;
    }
    get botOptions() {
      if (this.currentUser.ai_enabled_personas) {
        return this.currentUser.ai_enabled_personas.map(persona1 => {
          return {
            id: persona1.id,
            name: persona1.name,
            description: persona1.description
          };
        });
      }
    }
    get filterable() {
      return this.botOptions.length > 4;
    }
    get value() {
      return this._value;
    }
    set value(newValue1) {
      this._value = newValue1;
      this.preferredPersonaStore.setObject({
        key: "id",
        value: newValue1
      });
      this.composer.metaData = {
        ai_persona_id: newValue1
      };
    }
    get currentLlm() {
      return this.llm;
    }
    set currentLlm(newValue1) {
      this.llm = newValue1;
      const botUsername1 = this.currentUser.ai_enabled_chat_bots.find(bot1 => bot1.model_name === this.llm).username;
      this.preferredLlmStore.setObject({
        key: "id",
        value: newValue1
      });
      this.composer.set("targetRecipients", botUsername1);
    }
    get llmOptions() {
      return this.siteSettings.ai_bot_enabled_chat_bots.split("|").filter(Boolean).map(bot1 => {
        return {
          id: bot1,
          name: _I18n.default.t(`discourse_ai.ai_bot.bot_names.${bot1}`)
        };
      });
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="persona-llm-selector">
        <div class="gpt-persona">
          <DropdownSelectBox
            class="persona-llm-selector__persona-dropdown"
            @value={{this.value}}
            @content={{this.botOptions}}
            @options={{hash icon="robot" filterable=this.filterable}}
          />
        </div>
        <div class="llm-selector">
          <DropdownSelectBox
            class="persona-llm-selector__llm-dropdown"
            @value={{this.currentLlm}}
            @content={{this.llmOptions}}
            @options={{hash icon="globe"}}
          />
        </div>
      </div>
    
  */
  {
    "id": "d7DsFQBO",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"persona-llm-selector\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"gpt-persona\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"persona-llm-selector__persona-dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"botOptions\"]],[28,[32,1],null,[[\"icon\",\"filterable\"],[\"robot\",[30,0,[\"filterable\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"llm-selector\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"persona-llm-selector__llm-dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"currentLlm\"]],[30,0,[\"llmOptions\"]],[28,[32,1],null,[[\"icon\"],[\"globe\"]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-llm-selector.js",
    "scope": () => [_dropdownSelectBox.default, _helper.hash],
    "isStrictMode": true
  }), _BotSelector), _BotSelector), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "llm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});