define("discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _ThumbnailSuggestionItem;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ThumbnailSuggestionItem = _exports.default = (_class = (_ThumbnailSuggestionItem = class ThumbnailSuggestionItem extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selected", _descriptor, this);
      _initializerDefineProperty(this, "selectIcon", _descriptor2, this);
      _initializerDefineProperty(this, "selectLabel", _descriptor3, this);
    }
    toggleSelection(thumbnail1) {
      if (this.selected) {
        this.selectIcon = "far-circle";
        this.selectLabel = "discourse_ai.ai_helper.thumbnail_suggestions.select";
        this.selected = false;
        return this.args.removeSelection(thumbnail1);
      }
      this.selectIcon = "check-circle";
      this.selectLabel = "discourse_ai.ai_helper.thumbnail_suggestions.selected";
      this.selected = true;
      return this.args.addSelection(thumbnail1);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="ai-thumbnail-suggestions__item">
        <DButton
          @icon={{this.selectIcon}}
          @label={{this.selectLabel}}
          @action={{fn this.toggleSelection @thumbnail}}
          class={{if this.selected "btn-primary"}}
        />
        <img
          src={{@thumbnail.url}}
          loading="lazy"
          width={{@thumbnail.thumbnail_width}}
          height={{@thumbnail.thumbnail_height}}
        />
      </div>
    
  */
  {
    "id": "BsBgpDKw",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-thumbnail-suggestions__item\"],[12],[1,\"\\n      \"],[8,[32,0],[[16,0,[52,[30,0,[\"selected\"]],\"btn-primary\"]]],[[\"@icon\",\"@label\",\"@action\"],[[30,0,[\"selectIcon\"]],[30,0,[\"selectLabel\"]],[28,[32,1],[[30,0,[\"toggleSelection\"]],[30,1]],null]]],null],[1,\"\\n      \"],[10,\"img\"],[15,\"src\",[30,1,[\"url\"]]],[14,\"loading\",\"lazy\"],[15,\"width\",[30,1,[\"thumbnail_width\"]]],[15,\"height\",[30,1,[\"thumbnail_height\"]]],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@thumbnail\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item.js",
    "scope": () => [_dButton.default, _helper.fn],
    "isStrictMode": true
  }), _ThumbnailSuggestionItem), _ThumbnailSuggestionItem), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectIcon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "far-circle";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "discourse_ai.ai_helper.thumbnail_suggestions.select";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelection"), _class.prototype)), _class);
});