define("discourse/plugins/discourse-ai/discourse/controllers/admin-dashboard-sentiment", ["exports", "@ember/object", "admin/controllers/admin-dashboard-tab"], function (_exports, _object, _adminDashboardTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let AdminDashboardSentiment = _exports.default = (_dec = (0, _object.computed)("startDate", "endDate"), (_class = class AdminDashboardSentiment extends _adminDashboardTab.default {
    get filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate
      };
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "filters", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "filters"), _class.prototype)), _class));
});