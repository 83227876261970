define("discourse/plugins/discourse-ai/discourse/connectors/composer-after-save-or-cancel/ai-image-caption-loader", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/loading-spinner", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _loadingSpinner, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _AiImageCaptionLoader;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AiImageCaptionLoader = _exports.default = (_class = (_AiImageCaptionLoader = class AiImageCaptionLoader extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "imageCaptionPopup", _descriptor, this);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.imageCaptionPopup.showAutoCaptionLoader}}
        <div class="auto-image-caption-loader">
          {{loadingSpinner size="small"}}
          <span>{{i18n
              "discourse_ai.ai_helper.image_caption.automatic_caption_loading"
            }}</span>
        </div>
      {{/if}}
    
  */
  {
    "id": "/15Zu2iM",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"imageCaptionPopup\",\"showAutoCaptionLoader\"]],[[[1,\"      \"],[10,0],[14,0,\"auto-image-caption-loader\"],[12],[1,\"\\n        \"],[1,[28,[32,0],null,[[\"size\"],[\"small\"]]]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,1],[\"discourse_ai.ai_helper.image_caption.automatic_caption_loading\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/composer-after-save-or-cancel/ai-image-caption-loader.js",
    "scope": () => [_loadingSpinner.default, _i18n.default],
    "isStrictMode": true
  }), _AiImageCaptionLoader), _AiImageCaptionLoader), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "imageCaptionPopup", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});