define("discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse-common/helpers/i18n", "discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _dButton, _dModal, _dModalCancel, _i18n, _thumbnailSuggestionItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _ThumbnailSuggestions;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ThumbnailSuggestions = _exports.default = (_class = (_ThumbnailSuggestions = class ThumbnailSuggestions extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selectedImages", _descriptor, this);
    }
    get isDisabled() {
      return this.selectedImages.length === 0;
    }
    addSelection(selection1) {
      const thumbnailMarkdown1 = `![${selection1.original_filename}|${selection1.width}x${selection1.height}](${selection1.short_url})`;
      this.selectedImages = [...this.selectedImages, thumbnailMarkdown1];
    }
    removeSelection(selection1) {
      const thumbnailMarkdown1 = `![${selection1.original_filename}|${selection1.width}x${selection1.height}](${selection1.short_url})`;
      this.selectedImages = this.selectedImages.filter(thumbnail1 => {
        if (thumbnail1 !== thumbnailMarkdown1) {
          return thumbnail1;
        }
      });
    }
    appendSelectedImages() {
      const imageMarkdown1 = "\n\n" + this.selectedImages.join("\n");
      const dEditorInput1 = document.querySelector(".d-editor-input");
      dEditorInput1.setSelectionRange(dEditorInput1.value.length, dEditorInput1.value.length);
      dEditorInput1.focus();
      document.execCommand("insertText", false, imageMarkdown1);
      this.args.closeModal();
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        class="thumbnail-suggestions-modal"
        @title={{i18n "discourse_ai.ai_helper.thumbnail_suggestions.title"}}
        @closeModal={{@closeModal}}
      >
        <:body>
          <div class="ai-thumbnail-suggestions">
            {{#each @thumbnails as |thumbnail|}}
              <ThumbnailSuggestionItem
                @thumbnail={{thumbnail}}
                @addSelection={{this.addSelection}}
                @removeSelection={{this.removeSelection}}
              />
            {{/each}}
          </div>
        </:body>
  
        <:footer>
          <DButton
            @action={{this.appendSelectedImages}}
            @label="save"
            @disabled={{this.isDisabled}}
            class="btn-primary create"
          />
          <DModalCancel @close={{@closeModal}} />
        </:footer>
      </DModal>
    
  */
  {
    "id": "rVPkxzzs",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"thumbnail-suggestions-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_helper.thumbnail_suggestions.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"ai-thumbnail-suggestions\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2]],null]],null],null,[[[1,\"            \"],[8,[32,2],null,[[\"@thumbnail\",\"@addSelection\",\"@removeSelection\"],[[30,3],[30,0,[\"addSelection\"]],[30,0,[\"removeSelection\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,0,\"btn-primary create\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"appendSelectedImages\"]],\"save\",[30,0,[\"isDisabled\"]]]],null],[1,\"\\n        \"],[8,[32,4],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@thumbnails\",\"thumbnail\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions.js",
    "scope": () => [_dModal.default, _i18n.default, _thumbnailSuggestionItem.default, _dButton.default, _dModalCancel.default],
    "isStrictMode": true
  }), _ThumbnailSuggestions), _ThumbnailSuggestions), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedImages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "appendSelectedImages", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "appendSelectedImages"), _class.prototype)), _class);
});